<template>
  <div style="height: 40px;line-height: 40px" class="j_header">
    <!-- <img class="head_logo" src="../assets/img/head_logo.png" alt=""> -->
    <div class="logo">
     DIP 外观专利侵权预警系统
    </div>
    <div class="nav">
      <router-link
        :class="{ active: $route.name == 'Graphical' }"
        to="/graphical"
        >{{ $t("tab.txjs") }}</router-link
      >
      <router-link
        :class="{ active: $route.name == 'Description' }"
        to="/description"
        >{{ $t("tab.zlxjs") }}</router-link
      >
    </div>
    <div v-show="false" class="lang">
      <span
        @click="handlerCN"
        class="init"
        :class="{ active: lang_active == 'zh' }"
        >简</span
      >
      /
      <span
        @click="handlerEN"
        class="init"
        :class="{ active: lang_active == 'en' }"
        >EN</span
      >
    </div>
    <el-dropdown @command="handleCommand" v-if="userInfo.account">
      <span class="el-dropdown-link">
        {{ userInfo.account }}<i class="el-icon-arrow-down el-icon--right"></i>
      </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item command='a' class="el-icon-user">
          个人中心
        </el-dropdown-item>
        <el-dropdown-item  command='b' class="el-icon-refresh"> 退出 </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <router-link to="/login" v-else>请先登录</router-link>
  </div>
</template>

<script>
import {logout} from '@/api'
import {removeToken} from '@/utils/auth'
export default {
  data() {
    return {
      lang_active: "zh",
      nav_active: "",
      userInfo: JSON.parse(localStorage.getItem("userInfo")) || {},
    };
  },
  methods: {
    handlerCN() {
      this.$i18n.locale = "zh";
      this.lang_active = "zh";
    },
    handlerEN() {
      this.$i18n.locale = "en";
      this.lang_active = "en";
    },
    handleCommand(com){
      if(com == 'a'){
        this.$router.push('/user');
      }else if(com == 'b'){
        logout()
        .then(() => {
          localStorage.removeItem('userInfo');
          removeToken();
          this.userInfo = {}
          this.$router.push('/login');
        })
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.j_header {
  min-width: 1290px;
  > a {
    text-decoration: none;
    color: #d1d1d1;
  }
  box-sizing: border-box;
  width: 100%;
  background: #5f6a72;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1%;
  .head_logo{
    margin-left: 10px;
    margin-right: -30px;
    height: 80%;
  }
  .nav {
    flex: 1;
    display: flex;
    width: 700px;
    height: 100%;
    margin-left: 15px;
    > a {
      min-width: 150px;
      text-align: center;
      color: #d1d1d1;
      display: block;
      height: 100%;
      margin-right: 30px;
      font-size: 18px;
    }
    > a:link {
      text-decoration: none;
    }
    .active {
      color: #fff;
      background: #409eff;
    }
    > a:hover {
      color: #fff;
      background: #409eff;
    }
  }
  .lang {
    font-size: 28px;
    margin-right: 20px;
    .init {
      opacity: 0.5;
      cursor: pointer;
    }
    .active {
      opacity: 1;
    }
  }
  .logo {
    width: 16vw;
    min-width: 220px;
    text-align: center;
    height: 100%;
    font-size: 22px;
    color: #fff;
    cursor: default;
  }
}
</style>
<style>
  .el-dropdown-link {
    cursor: pointer;
    color: #d1d1d1;
    font-size: 18px;
  }
  .el-icon-arrow-down {
    font-size: 12px;
  }
  .el-dropdown-menu{
    width: 150px;
  }
</style>